import {
  isNull,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

type StorageKeys =
  | 'maxVisitedStep'
  | 'requiredParamsToGetReservation'
  | 'prevPathnameAndQuery'
  | 'ratePair'
  | 'userLevelNotFoundWarningShowed'
  | 'ingenicoQueries'
  | 'previousRoute'
  | 'guestData'
  | 'hotelMarketingAlertAlreadyShowed'
  | 'continueWithoutExtrasModalShowed'

export interface StorageClient {
  get: <T>(key: StorageKeys) => T | undefined
  set: <T>(key: StorageKeys, item: T) => void
  remove: (key: StorageKeys) => void
  getAll: () => string | undefined
}

export const sessionStorageClient: StorageClient = {
  get: <T>(key: StorageKeys): T | undefined => {
    if (isUndefined(window)) {
      return undefined
    }

    const item = sessionStorage.getItem(key)
    if (isNull(item)) {
      return undefined
    }

    return JSON.parse(item)
  },
  set: <T>(key: StorageKeys, item: T): void => {
    if (isUndefined(window)) {
      return undefined
    }

    sessionStorage.setItem(key, JSON.stringify(item))
  },
  remove: (key: StorageKeys): void => {
    if (isUndefined(window)) {
      return undefined
    }

    sessionStorage.removeItem(key)
  },
  getAll: () => JSON.stringify(sessionStorage, null, 2),
}
